<template>
  <v-container fluid>
    <TableViewComponent
      title="Table Avec > Applications métier par établissement"
      :viewZeroElement="activeViewZeroElement"
      messageZeroElement="Sélectionnez un établissement pour visualiser les applications définies"
      @addItemEvent="onAddElement()"
      addItemEvent=""
      itemLabel="application"
      itemsLabel="applications"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cette application ?"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
      :showAddAction="false"
      :viewTable="showTable"
    >
      <template v-slot:beforetable>
        <v-card-text>
          <div class="d-flex">
            <!-- Les établissements -->
            <v-autocomplete
              v-model="selectedEstab"
              :items="establishments"
              item-text="digitalName"
              return-object
              placeholder="Choisir un établissement"
              class="mx-4 my-0 pa-0"
              no-data-text="aucuns établissement"
              @change="onSelectedEstabChange()"
            >
            </v-autocomplete>
          </div>
        </v-card-text>

        <v-card-text v-if="!showTable && selectedEstab">
          <div class="mb-6 font-weight-bold">
            {{ messageStatutByDefault }}
          </div>
        </v-card-text>
      </template>
    </TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { ConfEstablishmentBusinessApplicationService } from "@/service/conf/conf_establishment_business_application_service.js";

import * as exceptions from "@/service/exception_to_message.js";
//import { defines as routes } from "@/router/defines.js";
import { roleCanEdit } from "@/service/role_service.js";
import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

const _LOCAL_APP = "application locale";
const _WEB_APP = "application web";

export default {
  name: "TableEstablishmentBusinessApp",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      entities: [],

      /** les services api */
      service: null,
      serviceEstablishment: null,

      /** L'établissement choisi */
      selectedEstab: null,
      selectedInitialEstablishment: null,
      /** la liste des établissements */
      establishments: [],

      /** affichage de la table */
      showTable: false,

      /** Message pour les établissemnet qui n'ont pas d'appliction métier */
      messageStatutByDefault: "Cet établissement n'a pas d'application métier.",
    };
  },
  methods: {
    /** On part vers la page d'ajout*/
    onAddElement() {
      //this.$router.push(routes.ticketingObjectSubobjects.add.path);
    },

    async load() {
      try {
        this.loading = true;
        this.establishments = [];
        this.entities = [];
        this.selectedEstab = null;
        this.selectedInitialEstablishment = null;

        /** Récupère la liste des noms digitaux des établissements de la base */
        this.establishments = await this.serviceEstablishment.getDigitalNames();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Chargement des applications  */
    async loadBusinessApp(establishmentId) {
      try {
        this.loading = true;

        this.entities = [];
        let webApp = await this.service.getAllBusinessAppLink(establishmentId);
        let localApp = await this.service.getAllBusinessAppLocal(
          establishmentId
        );

        // Ajout type d'app
        webApp.forEach((w) => (w.type = _WEB_APP));
        localApp.forEach((w) => (w.type = _LOCAL_APP));
        let apps = webApp.concat(localApp);

        /** Parcours des apps pour mise en forme */
        for (let app of apps) {
          // Ajout de la variable pour les apps qui ne l'ont pas
          if (!app.confname) {
            app.confname = "";
          }

          app.group = "";
          if (app.meta) {
            if (app.meta.type) {
              app.group = app.meta.type;
            }
          }

          this.entities.push(app);
        }

        if (!this.selectedEstab || this.entities.length == 0) {
          this.showTable = false;
        } else {
          this.showTable = true;
        }
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Evènement sur le changement de la sélection de l'établissement */
    async onSelectedEstabChange() {
      if (
        this.selectedEstab &&
        (!this.selectedInitialEstablishment ||
          this.selectedInitialEstablishment.id != this.selectedEstab.id)
      ) {
        this.selectedInitialEstablishment = this.selectedEstab;
        this.loadBusinessApp(this.selectedEstab.id);
      }
    },

    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Label",
          align: "start",
          sortable: false,
          value: "label",
        },
        {
          text: "Url",
          align: "start",
          sortable: false,
          value: "url",
        },
        {
          text: "Nom configuration",
          align: "start",
          sortable: false,
          value: "confname",
        },
        {
          text: "Groupe",
          align: "start",
          sortable: true,
          value: "group",
        },
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
        },
      ];

      return columns;
    },
  },

  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadConf];
    },

    activeViewZeroElement() {
      if (!this.selectedEstab || this.selectedEstab == '') {
        return true;
      }
      else { return false;}
    }
  },

  mounted() {
    this.service = new ConfEstablishmentBusinessApplicationService(
      this.$api.getConfEstablishmentBusinessApp()
    );
    this.serviceEstablishment = new EstablishmentsService(this.$api);

    this.canEdit = roleCanEdit();

    this.load();
  },
};
</script>
