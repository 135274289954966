import * as statusMapper from "@/service/error_request_mapper.js";


export class ConfEstablishmentBusinessApplicationService {
  constructor(api) {
    this.api = api;
  }

  /** Récupération des applications métier web */
  async getAllBusinessAppLink(establishmentId) {
    return this.api
      .getAllBusinessAppLink(establishmentId)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /** Récupération des applications métier web */
  async getAllBusinessAppLocal(establishmentId) {
    return this.api
      .getAllBusinessAppLocal(establishmentId)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

}