var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("TableViewComponent", {
        attrs: {
          title: "Table Avec > Applications métier par établissement",
          viewZeroElement: _vm.activeViewZeroElement,
          messageZeroElement:
            "Sélectionnez un établissement pour visualiser les applications définies",
          addItemEvent: "",
          itemLabel: "application",
          itemsLabel: "applications",
          items: _vm.entities,
          columns: _vm.buildColumns(),
          deleteItemLabel: "Voulez-vous supprimer cette application ?",
          loading: _vm.loading,
          rolesForEdition: _vm.rolesForEdition,
          rolesForAddDelete: _vm.rolesForAddDelete,
          rolesForRead: _vm.rolesForRead,
          showAddAction: false,
          viewTable: _vm.showTable,
        },
        on: {
          addItemEvent: function ($event) {
            return _vm.onAddElement()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "beforetable",
            fn: function () {
              return [
                _c("v-card-text", [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("v-autocomplete", {
                        staticClass: "mx-4 my-0 pa-0",
                        attrs: {
                          items: _vm.establishments,
                          "item-text": "digitalName",
                          "return-object": "",
                          placeholder: "Choisir un établissement",
                          "no-data-text": "aucuns établissement",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onSelectedEstabChange()
                          },
                        },
                        model: {
                          value: _vm.selectedEstab,
                          callback: function ($$v) {
                            _vm.selectedEstab = $$v
                          },
                          expression: "selectedEstab",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                !_vm.showTable && _vm.selectedEstab
                  ? _c("v-card-text", [
                      _c("div", { staticClass: "mb-6 font-weight-bold" }, [
                        _vm._v(" " + _vm._s(_vm.messageStatutByDefault) + " "),
                      ]),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }